export enum UpdateInscriptionTypes {
  STARTED = 'started',
  PENDING_PAYMENT = 'pending_payment',
  PAID = 'paid',
  ASSIGN_NUMBER = 'assign_number',
  URL_CREATED = 'url_created',
  PDF_CREATED = 'pdf_created',
  COUPON_APPLIED = 'coupon_applied',
  NO_ASSIGN_NUMBER = 'no_assign_number',
  CATEGORY_UPDATED = 'category_updated',
  CANCELLED = 'cancelled',
  DATA_UPDATED = 'data_updated',
  MERGED = 'merged',
  WALLET_REFUND = 'wallet_refund',
  CHARGEBACK = 'chargeback',
  CHANGE_EVENT = 'change_event',
  NUMBER_UPDATED = 'number_updated',
  TSHIRT_UPDATED = 'tshirt_updated',
  COMMENT_UPDATED = 'comment_updated',
  PAYMENT_DECLINED = 'payment_declined',
  TSHIRT_SAVED = 'tshirt_saved',
  DISTANCE_SAVED = 'distance_saved',
  WALLET_REMOVED = 'wallet_removed',
  MAIL_SENDED = 'mail_sended',
  QUESTIONS_SAVED = 'questions_saved',
  DEFROST_BALANCE = 'defrost_balance',
  DISTANCE_UPDATED = 'distance_updated',
}

export enum TypeCarousel {
  STELLAR = 'estelares',
  SERIALS = 'seriales',
  EXPERIENCES_CHALLENGES = 'experiencias-retos',
}

export enum UpdateSummaryInscription {
  UPDATE_COMMENT = 'Update comment',
  UPDATE_TSHIRT = 'Update t-shirt',
  UPDATE_RACE = 'Update race',
  UPDATE_NUMBER = 'Update number',
}

export enum LoadingState {
  loading = 'loading',
  saving = 'saving',
  deleting = 'deleting',
  updating = 'updating',
  searching = 'searching',
}

export enum RegionEnum {
  NACIONAL = 'nacional',
  BAJIO = 'bajio',
  CENTRO = 'centro',
  NOROESTE = 'noreste',
  NORTE = 'norte',
  SUR = 'sur',
  SURESTE = 'sureste',
}
