import { IRegion } from '@asdeporte/apolo-components/cjs/_common/js/dataType'
import { RegionEnum } from '@interfaces'

export const REGIONS_SEED: Partial<IRegion>[] = [
  {
    name: 'Bajio',
    regionid: 'd402630a-2b2c-4a4b-a4b9-c8b20d2d52ee',
    enum: RegionEnum.BAJIO,
  },
  {
    name: 'Centro',
    regionid: '92ddd8e1-6a39-4b9e-a423-791ae236a394',
    enum: RegionEnum.CENTRO,
  },
  {
    name: 'Nacional',
    regionid: 'b2736791-5332-4afb-b7cb-c8f61f667a6b',
    enum: RegionEnum.NACIONAL,
  },
  {
    name: 'Noreste',
    regionid: 'dd8c862f-5de9-4049-ba01-288288afbbd0',
    enum: RegionEnum.NOROESTE,
  },
  {
    name: 'Norte',
    regionid: '9239443e-f07d-4629-a67c-58dc8a26372a',
    enum: RegionEnum.NORTE,
  },
  {
    name: 'Sur',
    regionid: 'bec6ef62-4d88-4728-86c0-4d7d02d2c2d1',
    enum: RegionEnum.SUR,
  },
  {
    name: 'Sureste',
    regionid: '0f59fb09-9fe3-4062-8d0c-e7d129462ab5',
    enum: RegionEnum.SURESTE,
  },
]
