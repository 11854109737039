import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { GraphQLError } from 'graphql'
import { BranchEnum } from '@asdeporte/apolo-components/cjs/_common/js/dataType/enums'
import {
  ICustomQuestion,
  IDistance,
  IRange,
  IRegion,
  IYupError,
} from '@asdeporte/apolo-components/cjs/_common/js/dataType'
import { GENDERS } from '@utils/constants'
import { InternalAxiosRequestConfig } from 'axios'
import { getToken } from '@services/auth'
import { getSession } from 'next-auth/react'
import { REGIONS_SEED } from '@utils/regions.seed'
import { IUserRol } from '@context/organization/interface'

export const handleYupError = (error: yup.ValidationError): IYupError[] => {
  if (!error?.inner) return []

  return error.inner.map(({ path, message }) => ({
    path,
    message,
  }))
}

export const onlyNumbers = (value: string) => {
  const regex = /^[0-9]*$/
  return regex.test(value)
}

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const handleResponseError = (error: any): string[] => {
  if (error.status === 500) return ['Network error']

  const resp = error.response

  if (resp?.errors) {
    return resp.errors.map((err: GraphQLError) => err.message)
  }

  let messages = []
  if (resp && resp?.data) {
    if (typeof resp.data.message === 'string') messages.push(resp.data.message)

    if (Array.isArray(resp.data.message) && resp.data.message.length > 0) {
      messages = resp.data.message.map(msg => msg)
    }
  } else {
    messages.push(error.response)
  }

  return messages
}

export const copyToClipboard = async (text: string): Promise<void> => {
  await navigator.clipboard.writeText(text)
  toast.success('Copied')
}

export const transformAxiosHeaders = async (
  config: InternalAxiosRequestConfig<any>
): Promise<any> => {
  const token = await getToken()
  const session = await getSession()
  config.headers['Authorization'] = `Bearer ${token}`
  return config
}

export const transformGraphqlHeaders = async (request: any): Promise<any> => {
  const token = await getToken()
  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    },
  }
}

export const getHtmlTableFromArray = (
  data: any[],
  fieldsToShow: string[] = []
): string => {
  let tableHtml = '<table width="100%">'

  // Crea la cabecera de la tabla
  const headers = fieldsToShow.length > 0 ? fieldsToShow : Object.keys(data[0])
  tableHtml += '<thead><tr>'
  headers.forEach(header => (tableHtml += `<th>${header}</th>`))
  tableHtml += '</tr></thead>'

  // Crea cada fila de la tabla
  tableHtml += '<tbody>'
  data.forEach(row => {
    tableHtml += '<tr>'
    headers.forEach(header => (tableHtml += `<td>${row[header]}</td>`))
    tableHtml += '</tr>'
  })
  tableHtml += '</tbody>'

  tableHtml += '</table>'
  return tableHtml
}

export const getBranchName = (branch: string): string => {
  switch (branch) {
    case BranchEnum.MALE:
      return 'Varonil'
    case BranchEnum.FEMALE:
      return 'Femenil'
    case BranchEnum.MIXED:
      return 'Mixto'
  }
}

export const getGenderTranslationKey = (code: BranchEnum) => {
  return GENDERS[code]
}

export const getFormattedRanges = (ranges: Partial<IRange>[]) => {
  return ranges.map(({ distanceitemid, end, name, position, start }) => ({
    tempid: uuidv4(),
    distanceItemId: distanceitemid,
    end,
    name,
    position,
    start,
  }))
}

export const getFormattedDistances = (
  ranges: any[],
  distances: Partial<IDistance>[],
  reference_date: Date
) => {
  return distances.map(
    ({
      tempid,
      distance,
      distance_unit,
      branch,
      description,
      type,
      level,
      categories,
      sport,
      sportid,
    }) => {
      const tempranges = ranges
        .filter(range => {
          return range.distanceItemId !== undefined &&
            range.distanceItemId !== null
            ? range.distanceItemId === tempid
            : true
        })
        .map(range => range.tempid)

      return {
        tempid: uuidv4(),
        name: `${distance} ${distance_unit ? distance_unit : ''}`,
        branch: branch,
        distance,
        distance_unit,
        sportid: sport?.sportid || sportid,
        description,
        reference_date: reference_date,
        type,
        level,
        categories,
        tempranges,
        itemId: tempid,
      }
    }
  )
}

export const getFormattedQuestions = (
  distances: any[],
  questions: Partial<ICustomQuestion>[]
) => {
  return questions.map((question, key) => {
    const tempdistances = distances
      .filter(distance =>
        question.distances.some(d => d.tempid === distance.itemId)
      )
      .map(distance => distance.tempid)

    let options = []
    if (question.type === 'option') {
      options = question.options.map((option, key) => {
        return {
          name: option.name,
          position: key + 1,
          forks:
            option?.forks?.length > 0
              ? []
              : [
                  {
                    question: option?.forks[0]?.question,
                    position: key + 1,
                    disabled: option?.forks[0]?.disabled,
                    required: option?.forks[0]?.required,
                    type: option?.forks[0]?.type,
                    options:
                      option?.forks[0]?.type === 'option'
                        ? option?.forks[0]?.options.map(option => option.name)
                        : [],
                  },
                ],
        }
      })
    }

    return {
      tempid: uuidv4(),
      question: question.question,
      placeholder: question.question,
      position: key + 1,
      allusers: question.allusers,
      disabled: question.disabled,
      required: question.required,
      type: question.type,
      options,
      distances: tempdistances,
      tempdistances,
      range: question.type === 'range' ? question.range : null,
    }
  })
}

export const getRegionUser = (
  roles: Partial<IUserRol>[]
): Partial<IRegion>[] => {
  const regionRoles = roles.filter(role => role.category === 'REGION')

  const regions = regionRoles
    .map(regionRole =>
      REGIONS_SEED.find(
        region => region.name.toLowerCase() === regionRole.name.toLowerCase()
      )
    )
    .filter(Boolean)
  return regions
}
